<script lang="ts" setup>
import { Toaster as Sonner, type ToasterProps } from 'vue-sonner';

const props = defineProps<ToasterProps>();
</script>

<template>
  <Sonner
    class="toaster group"
    :class-names="{
      toast:
        'group toast 0 !group-[.toaster]:shadow-lg !group-[.toaster]:bg-zinc-950 !group-[.toaster]:text-zinc-50 !group-[.toaster]:border-zinc-800',
      description: ' !group-[.toast]:text-zinc-400',
      actionButton: ' !group-[.toast]:bg-zinc-50 !group-[.toast]:text-zinc-900',
      cancelButton:
        ' !group-[.toast]:bg-zinc-800 !group-[.toast]:text-zinc-400',
    }"
    v-bind="props"
  />
</template>
