export const appHead = {"link":[{"rel":"preconnect","href":"https://www.google-analytics.com"},{"rel":"preconnect","href":"https://www.gstatic.com"},{"rel":"preconnect","href":"https://www.google.com"}],"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"Nutzen Sie unsere innovative KI-Technologie, um neue Geschäftskunden im B2B-Sektor zu gewinnen. Optimieren Sie Ihre Vertriebsstrategie jetzt!"},{"property":"og:description","content":"Nutzen Sie unsere innovative KI-Technologie, um neue Geschäftskunden im B2B-Sektor zu gewinnen. Optimieren Sie Ihre Vertriebsstrategie jetzt!"},{"name":"keywords","content":"b2b, scan, marketing, vertrieb, akquise, neukundengewinnung, b2bscan, gewinn, vergleich, analyse, anschreiben, ai, ki, business, unternehmen"},{"property":"og:title","content":"B2B Scan"},{"property":"og:url","content":"https://www.b2bscan.com"},{"property":"og:image","content":"assets/images/b2bscan-horizontal.svg"},{"name":"robots","content":"index, follow"},{"property":"og:type","content":"website"}],"style":[],"script":[],"noscript":[],"title":"B2B Scan","htmlAttrs":{}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"