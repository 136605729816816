import type { DirectiveBinding } from 'vue';

const highlightIndexDirective = {
  mounted(el: HTMLElement, binding: DirectiveBinding<number>) {
    el.innerText = el.innerText.trim();
    const indexToHighlight = binding.value;

    if (indexToHighlight < 0 || indexToHighlight >= el.innerText.length) {
      console.warn('index exceeds text length');
      return;
    }

    const originalText = el.innerText;
    const highlightedText =
      originalText.substring(0, indexToHighlight) +
      `<span style="color: #f25151;">${originalText[indexToHighlight]}</span>` +
      originalText.substring(indexToHighlight + 1);

    el.innerHTML = highlightedText;
  },
};

export default highlightIndexDirective;
