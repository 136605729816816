<template>
  <div class="dark">
    <NuxtLayout>
      <CookieBanner />
      <NuxtPage />
    </NuxtLayout>
    <ClientOnly>
      <Toaster
        theme="dark"
        :duration="3000"
        :expand="true"
        rich-colors
        class="top-[88px]"
        position="top-right"
        close-button
        :toast-options="{
          style: {
            top: '88px',
            padding: '20px 24px',
            color: '#fff',
            fontSize: '18px',
          },
          class: '!text-regular-20',
          classes: {
            toast: 'bg-iq-black',
            title: 'text-red-400',
            description: 'text-red-400',
            actionButton: '!bg-iq-red',
            cancelButton: 'bg-orange-400',
            closeButton: 'toast-btn-close',
          },
        }"
      />
    </ClientOnly>
  </div>
</template>
<script setup lang="ts">
import { Toaster } from '@/components/ui/sonner';
import type { ConsentCookie } from './interfaces/consent-cookie.interface';

async function checkConsent() {
  const cookie = useCookie<ConsentCookie>('consent');

  if (cookie.value?.analytics) {
    useGtag().initialize();
    useGtag().enableAnalytics();
    console.log('Gtag enabled');
  } else {
    useGtag().disableAnalytics();
    console.log('Gtag disabled');
  }
}

onMounted(() => {
  checkConsent();
});
</script>
<style lang="scss" scoped>
:global(.toast-btn-close) {
  .svg {
    stroke: #fff !important;
    transform: scale(1.25) !important;
    top: -6px !important;
    left: -6px !important;
    stroke-width: 3px !important;
  }
}
</style>
