/* eslint-disable @typescript-eslint/no-explicit-any */
import { track } from '@vercel/analytics';

async function sendToServer(
  eventName: string,
  eventParams?: Record<string, any>
) {
  const clientId = await getClientId();

  await $fetch('/api/a', {
    method: 'POST',
    body: JSON.stringify({ eventName, eventParams, clientId }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

async function getClientId () {
    return new Promise<string>((resolve) => {
      const timeout = setTimeout(() => {
        return resolve('anonymous');
      }, 100);

      const gtagId = useRuntimeConfig().public.gtag.id ?? '';
      useGtag().gtag('get', gtagId, 'client_id', (clientId) => {
        clearTimeout(timeout);
        const id = (clientId as string) ?? 'anonymous';
        return resolve(id);
      });
    });
  };

export default async function trackAtServer (
  eventName: string,
  eventParams?: Record<string, any>
) {
  try {
    useTrackEvent(eventName, eventParams);
    track(eventName, eventParams);

    sendToServer(eventName, eventParams);
  } catch (e) {
    console.error(e);
  }
};
