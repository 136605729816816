import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4._ywjzn7nz5mksr5qszng4qoipbe/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/vercel/path0/middleware/auth.global.ts";
import custom_45layout_45global from "/vercel/path0/middleware/custom.layout.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4._ywjzn7nz5mksr5qszng4qoipbe/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  custom_45layout_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  protected: () => import("/vercel/path0/middleware/protected.ts"),
  "stores-init": () => import("/vercel/path0/middleware/stores.init.ts")
}