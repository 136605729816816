import { default as archiveK4hpRi3eWHMeta } from "/vercel/path0/pages/archive.vue?macro=true";
import { default as checkoutlZQLvi17ODMeta } from "/vercel/path0/pages/checkout.vue?macro=true";
import { default as creditsXLudl7yq1zMeta } from "/vercel/path0/pages/credits.vue?macro=true";
import { default as imprintiuNFFLYTouMeta } from "/vercel/path0/pages/imprint.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91token_93VwOOSYwjSdMeta } from "/vercel/path0/pages/invite/[token].vue?macro=true";
import { default as leadsWP8xhf6a9nMeta } from "/vercel/path0/pages/leads.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as organizationwcNStta7tSMeta } from "/vercel/path0/pages/organization.vue?macro=true";
import { default as _91token_93hTDxOgyFeIMeta } from "/vercel/path0/pages/password-reset/[token].vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as scanGCBFGK3hRiMeta } from "/vercel/path0/pages/scan.vue?macro=true";
import { default as scanprofileyNPnl6TBs0Meta } from "/vercel/path0/pages/scanprofile.vue?macro=true";
import { default as userprofilekwLVsw0qULMeta } from "/vercel/path0/pages/userprofile.vue?macro=true";
export default [
  {
    name: "archive",
    path: "/archive",
    meta: archiveK4hpRi3eWHMeta || {},
    component: () => import("/vercel/path0/pages/archive.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/vercel/path0/pages/checkout.vue")
  },
  {
    name: "credits",
    path: "/credits",
    meta: creditsXLudl7yq1zMeta || {},
    component: () => import("/vercel/path0/pages/credits.vue")
  },
  {
    name: "imprint",
    path: "/imprint",
    component: () => import("/vercel/path0/pages/imprint.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "invite-token",
    path: "/invite/:token()",
    component: () => import("/vercel/path0/pages/invite/[token].vue")
  },
  {
    name: "leads",
    path: "/leads",
    component: () => import("/vercel/path0/pages/leads.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "organization",
    path: "/organization",
    component: () => import("/vercel/path0/pages/organization.vue")
  },
  {
    name: "password-reset-token",
    path: "/password-reset/:token()",
    component: () => import("/vercel/path0/pages/password-reset/[token].vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "scan",
    path: "/scan",
    meta: scanGCBFGK3hRiMeta || {},
    component: () => import("/vercel/path0/pages/scan.vue")
  },
  {
    name: "scanprofile",
    path: "/scanprofile",
    meta: scanprofileyNPnl6TBs0Meta || {},
    component: () => import("/vercel/path0/pages/scanprofile.vue")
  },
  {
    name: "userprofile",
    path: "/userprofile",
    meta: userprofilekwLVsw0qULMeta || {},
    component: () => import("/vercel/path0/pages/userprofile.vue")
  }
]