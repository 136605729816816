import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin((nuxtApp) => {
  const siteKey = useRuntimeConfig().public.recaptcha.siteKey;
  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey,
    loaderOptions: {
      useEnterprise: false,
      autoHideBadge: true,
      explicitRenderParameters: {
        badge: 'bottomright',
      },
    },
  });
});
