<template>
  <transition name="fade">
    <div
      v-if="showBanner"
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full min-h-[100dvh] bg-opacity-80 bg-iq-grey-dark"
    >
      <div
        class="w-full p-4 mx-2 text-left rounded-md bg-iq-black md:w-4/5 2xl:w-1/2"
      >
        <div v-if="showBanner && !showCookieOptions">
          <div class="mb-4 text-semibold-24">
            {{ t('label.privacy') }}
          </div>
          <p class="mb-4 text-regular-18">
            {{ t('label.improve-experience') }}
          </p>
          <div
            id="buttons"
            class="flex flex-col justify-between float-none mb-2 lg:float-right text-regular-18 lg:flex-row gap-y-2"
          >
            <UiButton
              class="mr-2 border bg-inherit hover:bg-iq-grey-dark border-iq-white text-regular-18"
              @click="showCookieOptions = !showCookieOptions"
              >{{ t('button.adjust-settings') }}</UiButton
            >

            <UiButton
              class="bg-iq-green hover:bg-iq-green/[0.7] mr-2 mb-2 text-regular-18 !text-white"
              @click="acceptAll()"
              >{{ t('button.accept-all') }}</UiButton
            >
          </div>
        </div>
        <div v-if="showCookieOptions">
          <div class="flex flex-col gap-4">
            <div>
              <div class="flex items-center mb-2">
                <UiSwitch disabled checked />
                <span class="ml-2 text-medium-18">{{
                  t('label.necessary-cookies')
                }}</span>
              </div>

              <p class="text-regular-16">
                {{ t('label.necessary-cookies-text') }}
              </p>
            </div>
            <hr class="border-iq-beige" />

            <div>
              <div class="flex items-center mb-2">
                <UiSwitch v-model:checked="consent.analytics" />
                <span class="ml-2 text-medium-18">{{
                  t('label.analytics-cookies')
                }}</span>
              </div>

              <p class="text-regular-16">
                {{ t('label.analytics-cookies-text') }}
              </p>
            </div>

            <hr class="border-iq-black" />

            <div class="mb-2">
              <div class="flex items-center mb-2">
                <UiSwitch v-model:checked="consent.info" />
                <span class="ml-2 text-medium-18">{{
                  t('label.info-cookies')
                }}</span>
              </div>

              <p class="text-regular-16">
                {{ t('label.info-cookies-text') }}
              </p>
            </div>
          </div>
          <div class="float-right mt-3">
            <UiButton
              class="bg-iq-green hover:bg-iq-green/[0.7] mr-2 mb-2 text-regular-18 !text-white"
              @click="applyConsent()"
              >{{ t('button.save-settings') }}</UiButton
            >
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import type { ConsentCookie } from '@/interfaces/consent-cookie.interface';
const { t } = useI18n({
  useScope: 'local',
});
const consent = reactive<ConsentCookie>({
  analytics: true,
  necessary: true,
  info: true,
  maxAge: 31536000,
  expires: new Date(Date.now() + 31536000 * 1000),
});

const cookie = useCookie<ConsentCookie>('consent', consent);

const showCookieOptions = ref(false);

const showBanner = computed(() => !cookie.value);

function acceptAll() {
  consent.analytics = true;
  consent.info = true;
  consent.necessary = true;
  applyConsent();
}

function applyConsent() {
  if (consent.analytics) {
    useGtag().initialize();
    useGtag().enableAnalytics();
    console.log('Gtag enabled');
  } else {
    useGtag().disableAnalytics();
    console.log('Gtag disabled');
  }

  trackAtServer('consent', {
    analytics: consent.analytics,
    info: consent.info,
  });

  cookie.value = consent;
}
</script>
<i18n lang="json">
{
  "de": {
    "label": {
      "privacy": "Deine Privatsphäre ist uns wichtig",
      "improve-experience": "Wir nutzen Cookies, um dein Erlebnis auf unserer Webseite zu verbessern, kontinuierlich an Verbesserungen zu arbeiten und dich über Neuigkeiten zu informieren.",
      "necessary-cookies": "Notwendige Cookies",
      "necessary-cookies-text": "Unsere Webseite nutzt essenzielle Cookies für Basisfunktionen wie die Seitennavigation und den Zugriff auf sichere Bereiche. Ohne diese Cookies kann die Webseite nicht richtig funktionieren.",
      "analytics-cookies": "Analyse und Personalisierung",
      "analytics-cookies-text": "Mit diesen Cookies können wir dein Nutzererlebnis verbessern und unsere Webseite basierend auf deinem Feedback optimieren.",
      "info-cookies": "Informations-Cookies",
      "info-cookies-text": "Diese Cookies ermöglichen es uns, dir maßgeschneiderte Informationen und Angebote zu unserer Lösung auf unserer Webseite zu präsentieren."
    },
    "button": {
      "adjust-settings": "Einstellungen anpassen",
      "accept-all": "Alle Cookies akzeptieren",
      "save-settings": "Einstellungen speichern & schließen"
    }
  },
  "en": {
    "label": {
      "privacy": "Your privacy is important to us",
      "improve-experience": "We use cookies to improve your experience on our website, continuously work on improvements, and inform you about news.",
      "necessary-cookies": "Necessary Cookies",
      "necessary-cookies-text": "Our website uses essential cookies for basic functions such as page navigation and access to secure areas. Without these cookies, the website cannot function properly.",
      "analytics-cookies": "Analytics and Personalization",
      "analytics-cookies-text": "With these cookies, we can improve your user experience and optimize our website based on your feedback.",
      "nfo-cookies": "Information Cookies",
      "info-cookies-text": "These cookies allow us to present you with tailored information and offers about our solution on our website."
    },
    "button": {
      "adjust-settings": "Adjust settings",
      "accept-all": "Accept all cookies",
      "save-settings": "Save & close"
    }
  }
}
</i18n>
