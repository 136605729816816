import type { User } from 'lucia';
import { toast } from 'vue-sonner';
export const useUserStore = defineStore('user', () => {
  const user = ref<User | null | undefined>(null);
  const priceIdForRedirect = ref<string | null>(null);

  const { data: unpaidInvoices, refresh: refreshUnpaidInvoices } = useFetch<{
    hasUnpaidInvoices: boolean;
    invoiceUrls: string[];
  }>(`/api/stripe/unpaid-invoices`);

  async function update(args: { name?: string }) {
    if (!user.value) {
      throw new Error('No user logged in');
    }

    trackAtServer('user_save');

    try {
      const updatedUser = await $fetch<User>(`/api/users/me`, {
        method: 'PATCH',
        body: { ...args },
      });

      user.value = updatedUser;

      toast('Dein Profil wurde aktualisiert');
    } catch (e) {
      console.error(e);
      toast.error(
        'Ein Fehler ist aufgetreten. Bitte versuche es später noch einmal',
        {
          duration: 1000000,
        }
      );
      clearError();
    }
  }

  function clear() {
    user.value = null;
    priceIdForRedirect.value = null;
    unpaidInvoices.value = {
      hasUnpaidInvoices: false,
      invoiceUrls: [],
    };
  }

  return {

    
    priceIdForRedirect,
    unpaidInvoices,
    refreshUnpaidInvoices,
    update,
    clear,
    user,
  };
});
