import revive_payload_client_EFJYdrlfcY from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4._ywjzn7nz5mksr5qszng4qoipbe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KJwwXZhaxr from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4._ywjzn7nz5mksr5qszng4qoipbe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mMUBGzlQ2M from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4._ywjzn7nz5mksr5qszng4qoipbe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_bI3JbmiYHZ from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.21.3_vite@5.4.6_@types+node@20.16.5_sass@1.79_xo74djg6bx3v7n5dqtdcld666u/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_kkwWjWNgUc from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4._ywjzn7nz5mksr5qszng4qoipbe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_gCYqv8UNFm from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4._ywjzn7nz5mksr5qszng4qoipbe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_oMhtwydD1o from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4._ywjzn7nz5mksr5qszng4qoipbe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2fyCFI4icE from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4._ywjzn7nz5mksr5qszng4qoipbe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_FfrPImcCvs from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_typescript@5.6.2_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_P0RUBQiOGN from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4._ywjzn7nz5mksr5qszng4qoipbe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_TybWhgbUPg from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.3_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_6xmJtSFSPH from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.3_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import titles_OpsNUNdQth from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.14_h3@1.12.0_magicast@0.3.5_rollup@4.21.3_vite@5.4.6_@types+node@20.16.5_jjf3z75xdri2f6c3yrzcdbz4y4/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_kv44GUPXEI from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.14_h3@1.12.0_magicast@0.3.5_rollup@4.21.3_vite@5.4.6_@types+node@20.16.5_jjf3z75xdri2f6c3yrzcdbz4y4/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_uflj7XfttN from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.21.3_vite@5.4.6_@types+node@20.16.5_sass@1_ictfouuswh4rtwejbqvu4u4o7a/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_X6Qfem0QwO from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.21.3_vite@5.4.6_@types+node@20.16.5_sass@1_ictfouuswh4rtwejbqvu4u4o7a/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_3I26Gi6qdo from "/vercel/path0/node_modules/.pnpm/nuxt-pdfeasy@1.4.0_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/nuxt-pdfeasy/dist/runtime/plugin.mjs";
import plugin_client_O6OyJqusqA from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@2.1.0_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import recaptcha_client_ElRG0N5AcO from "/vercel/path0/plugins/recaptcha.client.ts";
import directives_8CcCirWtnE from "/vercel/path0/plugins/directives.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/plugins/sentry.ts";
import vercel_analytics_client_JwEi2bEO5D from "/vercel/path0/plugins/vercel-analytics.client.ts";
export default [
  revive_payload_client_EFJYdrlfcY,
  unhead_KJwwXZhaxr,
  router_mMUBGzlQ2M,
  _0_siteConfig_bI3JbmiYHZ,
  payload_client_kkwWjWNgUc,
  navigation_repaint_client_gCYqv8UNFm,
  check_outdated_build_client_oMhtwydD1o,
  chunk_reload_client_2fyCFI4icE,
  plugin_vue3_FfrPImcCvs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_P0RUBQiOGN,
  switch_locale_path_ssr_TybWhgbUPg,
  i18n_6xmJtSFSPH,
  titles_OpsNUNdQth,
  defaults_kv44GUPXEI,
  siteConfig_uflj7XfttN,
  inferSeoMetaPlugin_X6Qfem0QwO,
  plugin_3I26Gi6qdo,
  plugin_client_O6OyJqusqA,
  recaptcha_client_ElRG0N5AcO,
  directives_8CcCirWtnE,
  sentry_3AyO8nEfhE,
  vercel_analytics_client_JwEi2bEO5D
]