import type { User } from 'lucia';

export default defineNuxtRouteMiddleware(async () => {
  const userStore = useUserStore();

  try {
    const user = await $fetch<User>('/api/users/me');

    userStore.user = user;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    //user is logged out
    clearError();
    console.log('user is logged out');
  }
});
