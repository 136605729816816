export default defineNuxtRouteMiddleware((to) => {
  // Set the layout on the route you are navigating _to_

  const user = useUserStore().user;
  if (to.path === '/') {
    return setPageLayout('default');
  } else if (to.path !== '/' && !user) {
    return setPageLayout('default');
  } else {
    return setPageLayout('logged-in');
  }
});
